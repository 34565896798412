* {
    margin: 0px;
    padding: 0px;
  }

  :root {
    --primary-color: #056406;
    --second-colo: #02C37E;
    --third-color:  #116a9e;
    --white-color:white;
    --litegreen-color:#D4FFE3;
  }  

  a{
    text-decoration: none;
    color: black;
  }

  /* <<<<<<<<<<<<<<<<<<<<<<<<custom-services>>>>>>>>>>>>>>>>>>>> */
.custom-services{
    border: 0px solid red;
    margin:0px -12px;
    padding: 70px;
}
/* .custom-services h2 span{
   border-bottom: 4px solid red;
} */

.custom-services h2 span {
    position: relative;
    display: inline-block;
    padding: 10px;
 }
 
 .custom-services h2 span::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: 50%; /* Adjust the width to 50% of the span */
    border-bottom: 4px solid var(--primary-color); /* Adjust the border style and width as needed */
 }
.custom-inside-services{
    border:0px solid red;
    padding: 30px 40px;
    text-align: center;
    /* box-shadow: 10px 10px 10px 10px grey; */
    box-shadow: 1px 2px 11.5px 0px grey;
    border-radius: 30px;
    margin-top: 50px;

}
.custom-inside-services img{
    width:100%;
}
.custom-inside-services h5:hover{
    color: var(--primary-color);
}
  /* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

  /* <<<<<<<<<<<<<<<<<<<<<<<<<<<<custom-goldbuyer>>>>>>>>>>>>>>>>> */
.custom-goldbuyer{
  border:0px solid red;
  /* padding: 1px 20px; */
  margin-top: -1px;
}

/* @media (max-width: 990px) {
  .custom-goldbuyer {
    margin-top: 30px;
  }
} */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.custom-swiper-gold{
  background-image: url("../image/sk-gold-slider1.jpg");
  height: 400px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-gold-btn{
   background-color: var(--primary-color);
   padding: 10px 20px;
   margin-top: 20px;
   color: white;
}

.inside-swiper-gold{
  border:0px solid red;
}

.custom-doldbuyer-2{
  border:0px solid red;
  padding: 30px;

}
.custom-color-1{
  color: var(--primary-color);
  font-weight: bold;
}
.custom-color-1 p{
  margin-bottom: 20px;
}
/* .custom-right-gold{

} */
.sk-gold-buyer-title {
  position: relative;
  display: inline-block;
  padding-bottom: 10px;
}

.sk-gold-buyer-title::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20%;
  border-bottom: 2px solid black; /* Adjust the thickness and color as needed */
}
/* <<<<<<<<<<<<<<<<<<<<<<custom-goldbuyer-3>>>>>>>>>>>>>>>>>>>>> */
.custom-goldbuyer-3{
  border:0px solid red;
  padding: 30px;
  background-color: #f3f3f3 !important;
}
.custom-gold-box{
  border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 1px 13px gray;
}
.custom-gold-box img{
  width:100%;
}
.custom-gold-box p{
  padding: 10px;
  background-color: var(--primary-color);
  color:white;
  font-weight: bold;
}

.custom-insidegoldbuyer h2 span {
  position: relative;
  display: inline-block;
  padding-bottom: 20px;
}

.custom-insidegoldbuyer h2 span::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 50%; /* Adjust the width to 50% of the span */
  border-bottom: 4px solid var(--primary-color); /* Adjust the border style and width as needed */
}
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>> */
  /* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>> */

  /* <<<<<<<<<<<<<modal>>>>>>>>>>> */
  .custom-top-modal{
    padding: 0px 30px;
    border-radius: 25px;
    background: #056406B2;
    color: var(--white-color);

  }
.custom-header-modal{
  border:0px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-button{
  position: absolute;
  right: 10px;
  display: flex;
  padding: 7px;
  border-radius: 25px;
  border: none;
  color: red;
  font-size: 20px;
  box-shadow: 0px 3px 3px gray;
  top:10px;
}
.modal-input{
    border:none ;
    border-bottom: 1px solid gray;

}
.custom-otp-btn{
  background-color: #ff9607;
  padding: 10px;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}
.custom-otp-btn-1{
  padding: 10px 20px;
  background-color: transparent;
  border-radius: 5px;
  color: var(--white-color);
  border: 1px solid var(--white-color);
}
.custom-otp-btn-2{
  background-color:#ff9607;
  padding: 10px 50px;
  color: var(--white-color);

  border-radius: 20px;
  border: none;
  letter-spacing: 1px;
}
.custom-top-modal p{
  font-size: 13px;
  margin-top: 20px;
}

.contact-1{
  font-size: 25px !important;
}

.contact-1 svg{
  border: 1px solid yellow;
  padding: 3px;
  border-radius: 50px;
}

  /* <<<<<<<<<<<<<<<<<end>>>>>>>>> */

  @media(min-width:280px) and (max-width:991px){
    .custom-top-modal{
        padding: 0px;
    }

    .commeing-soon-modal p{
      font-size: 20px !important;
    }
    .contact-1{
      font-size: 18px !important;
    }
  }


